<template>
  <BaseFinancialStatementSnapshot docType="CASH_FLOW" />
</template>

<script>
import BaseFinancialStatementSnapshot from '@/component/BaseFinancialStatementSnapshot.vue'

export default {
  name: 'CashFlowList',
  components: { BaseFinancialStatementSnapshot },
}
</script>
